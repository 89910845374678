<template>
  <div>
    <!-- Quill -->
    <!-- npm install quill -->
    <div ref="editor" class="quill-container"></div>
    <div id="counter" class="text-right"></div>
    <input type="file" id="getFile" class="el-col-0" accept="image/*" @change="uploadFunction($event)" />

    <!-- TESTING -->
    <!-- <el-divider></el-divider>
    <div class="content ql-editor" v-html="getHtml"></div>
    <div class="content ql-editor">{{ getHtml }}</div> -->

  </div>
</template>

<script>
import { upload } from "@/api/utility"

// WYSIWYG HTML Editors - Quill
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

import { quillPlaceholderMixin } from "./_mixins/placeholder";

export default {
  name: "HTMLEditor",
  mixins: [quillPlaceholderMixin],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    theme: { type: String, default: "snow" }, // Editor Theme
    toolbarOptions: {
      type: Object, default: () => {  // Editor Custom Toolbar
        let toolbar = {
          container:
            [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              ['link', 'image','video'],

              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              // [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean']
            ],
          handlers:
          {
            'image': function () {
              document.getElementById('getFile').click()
            }
          }
        }
        return toolbar
      }
    },
    length: {
      type: Number,
      default: 0
    },
    insertMiddleAds: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      editor: null,
    }
  },
  computed: {
    getHtml() {
      // console.log(this.editor)
      if (this.editor) return this.editor.getText() ? this.editor.root.innerHTML : ""
      else return ""
    },
  },
  watch: {
    // modelValue(val) {
    //   if(val){
    //     // this.modelValue = val
    //     this.editor.root.innerHTML = val;
    //   } else {
    //     // this.modelValue = val
    //     this.editor.root.innerHTML = val;
    //     this.initPlaceholder(this.editor)
    //   }
    // }, 
  },
  methods: {
    update() {
      // console.log('update')
      const content = this.editor.getText() ? this.editor.root.innerHTML : ""
      this.$emit("update:modelValue",content);
      // this.textLength = this.editor.getText().trim().length

      const contentX = this.editor.getContents()
      const contextLength = contentX.ops.length

      if (contextLength > 3 ) this.textLength = 1;
      else this.textLength = this.editor.getText().trim().length;

      this.$emit("update:length",this.textLength);

      // console.log(this.textLength)
      // console.log(content)
      // console.log(contextLength)

    },
    initEditor() {
      var _this = this;

      this.editor = new Quill(this.$refs.editor, {
        modules: {
          toolbar: this.toolbarOptions,
          // counter: {
          //   container: '#counter',
          //   unit: 'character'
          // }
        },
        theme: this.theme,
        placeholder: "Type something in here!",
        scrollingContainer: '#scrolling-container',
      });

      this.editor.root.innerHTML = this.modelValue;
      this.editor.on("text-change", function () {
        return _this.update();
      });
      if (!this.modelValue) this.initPlaceholder(this.editor)

    },
    initCounter() {
      Quill.register('modules/counter', function (quill, options) {
        var container = document.querySelector(options.container);
        quill.on('text-change', function () {
          var text = quill.getText();
          if (options.unit === 'word') {
            container.innerText = text.split(/\s+/).length + ' words';
          } else {
            // Note: Minus the default spacing value 3 
            container.innerText = (text.length - 3) + '/3000';
          }
        });
      });
    },
    uploadFunction(e) {
      const rawFile = e.target.files[0];
      e.target.value = ''
      if (rawFile) {
        const formData = new FormData();
        formData.append("file", rawFile);

        upload(formData).then(response => {
            const result = response?.result
            if (!result?.haveError) {
              console.log("Upload successfully!")
              this.addFile(result.url)
            }
          })
      }
    },
    addFile(url) {
      // Get position 
      const range = this.editor.getSelection()
      // Set image from your server to quill editor
      this.editor.insertEmbed(range.index , 'image', url)
    }
  },
  mounted() {
    // No need counter for now
    // this.initCounter()
    this.initEditor()
  }
}
</script>
<style lang="scss">
.quill-container {
  height: 60vh;
  padding: 0;
  border-color: #dcdfe6 !important;
  border-radius: 0 0 4px 4px;

  .ql-editor {
    max-height: 60vh;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .adv-block {
      text-align: center;
      padding: 2rem;
      background-color: #f0f0f0;
      color: #8a8a8a;
      letter-spacing: .2rem;
    }
    .ql-video {
        display: block;
        max-width: 100%;
        width: 55rem;
        height: 31rem;
        margin: 0 auto;
    }
    img {
      max-height: 50rem;
      max-width: 100%;
      height: 100%;
      width: auto;
      margin: 0 auto;
      display: block;
    }
  }
}

.ql-toolbar.ql-snow {
  border-color: #dcdfe6 !important;
  border-radius: 4px 4px 0 0;
}

.ql-snow .ql-tooltip {
    left: 0 !important;
}
</style>