import Quill from "quill";
const Delta = Quill.import("delta");
const Embed = Quill.import("blots/embed");

export const quillPlaceholderMixin = {
  data() {
    class PlaceholderBlot extends Embed {
      static create(value) {
        let node = super.create(value);

        node.setAttribute("id", value.id);
        node.setAttribute("data-label", value.label);
        node.setAttribute("spellcheck", false);
        node.setAttribute("contenteditable", false);
        node.innerText = `${value.label}`;

        return node;
      }

      static value(domNode) {
        return domNode.dataset;
      }

      length() {
        return 1;
      }

      deleteAt() {
        return false;
      }
    }

    PlaceholderBlot.blotName = "placeholder";
    PlaceholderBlot.tagName = "pre";
    PlaceholderBlot.className = "adv-block";

    Quill.register(PlaceholderBlot);

    class Placeholder {
      constructor(quill) {
        this.quill = quill;
        this.onTextChange = this.onTextChange.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);

        this.quill.on(Quill.events.TEXT_CHANGE, this.onTextChange);
        this.quill.on(Quill.events.SELECTION_CHANGE, this.onSelectionChange);
      }      
    }

    Quill.register("modules/placeholder", Placeholder);
  },
  methods: {
    initPlaceholder(editor){
      const range = editor.getSelection();
      // if (!range || range.length != 0) return
      const position = range && range.index || 0;
      editor.insertEmbed(position, 'text', '');
      if (this.insertMiddleAds) {
        editor.insertEmbed(position + 10, 'placeholder', {id: 'adv-middle', label: 'Advertisement Here'}, Quill.sources.USER);
      }
      editor.insertEmbed(position + 11, 'text', '');
      editor.setSelection(position + 0);
    },

    onTextChange(_, oldDelta, source) {
      if (source !== Quill.sources.API) {
        const currrentContents = this.quill.getContents();
        const delta = currrentContents.diff(oldDelta);

        const revertOps = delta.ops.filter(
          (op) => op.insert && op.insert.placeholder
        );
        if (!revertOps.length) return;

        const [retainOp] = delta.ops.filter((op) => op.retain);

        if (retainOp) {
          this.quill.updateContents(new Delta([retainOp, ...revertOps]));
          this.quill.setSelection(retainOp.retain + 1 + revertOps.length);
        } else {
          this.quill.updateContents(new Delta(revertOps));
          this.quill.setSelection(revertOps.length);
        }
      }
    },

    onSelectionChange(range, oldRange, source) {
      //     if (range.length) return

      console.log("a, b, c", range, oldRange, source);
      //     const nativeRange = this.quill.selection.getNativeRange()
    }
  },
};
